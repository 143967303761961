import React from "react";
import { FormattedMessage } from "react-intl";

import SEO from "../../components/seo";

import ConversionRow from "../../components/ConversionRow";

import SolutionsChat from "../../images/solutions-chat.png";
import CalendarBlockedSuggestions from "../../images/calendar-blocked-suggestions.png";
import SchedulingWithRecommendations from "../../images/scheduling-with-recommendations.png";
import ExternalLink from "../../components/ExternalLink";

const Entrepreneurs = () => {
  return (
    <>
      <SEO
        title="entrepreneurs.seo.title"
        description="entrepreneurs.seo.description"
      />
      <div className="bg-cream relative">
        <div className="container mx-auto">
          <div className="relative flex flex-col-reverse lg:flex-row lg:flex-no-wrap z-20 relative justify-between items-center pt-32 py-24 lg:py-40">
            <div className="flex flex-col w-full items-center">
              <span className="uppercase text-xl mb-8">
                <FormattedMessage id="nav.entrepreneurs" />
              </span>
              <h1 className="text-center leading-tight mb-4 break-words w-full">
                <FormattedMessage id="entrepreneurs.headline" />
              </h1>
              <div className="flex flex-row items-center">
                <ExternalLink
                  to="https://meet.appointer.com"
                  className="button shadow-none button-xl mt-12 mx-2 ga-start-now-button"
                >
                  <FormattedMessage id="common.start-scheduling" />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-32">
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-col w-full md:w-1/2 xl:w-1/3 md:pr-4">
            <span className="font-bold text-3xl text-center md:text-left leading-tight">
              <FormattedMessage id="entrepreneurs.reason-1.headline" />
            </span>
            <p className="text-xl text-center md:text-left mt-8">
              <FormattedMessage id="entrepreneurs.reason-1.text" />
            </p>
          </div>
          <div className="flex flex-col items-center w-full md:w-1/2 xl:w-2/3 md:pl-4">
            <div className="max-w-xl w-full">
              <img src={SolutionsChat} alt="" />
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap mt-24">
          <div className="flex flex-col w-full md:w-1/2 xl:w-1/3 md:pr-4">
            <span className="font-bold text-3xl text-center md:text-left leading-tight">
              <FormattedMessage id="entrepreneurs.reason-2.headline" />
            </span>
            <p className="text-xl text-center md:text-left mt-8">
              <FormattedMessage id="entrepreneurs.reason-2.text" />
            </p>
          </div>
          <div className="flex flex-col items-center w-full md:w-1/2 xl:w-2/3 md:pl-4">
            <div className="max-w-xl w-full">
              <img src={CalendarBlockedSuggestions} className="px-6" alt="" />
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap mt-24">
          <div className="flex flex-col w-full md:w-1/2 xl:w-1/3 md:pr-4">
            <span className="font-bold text-3xl text-center md:text-left leading-tight">
              <FormattedMessage id="entrepreneurs.reason-3.headline" />
            </span>
            <p className="text-xl text-center md:text-left mt-8">
              <FormattedMessage id="entrepreneurs.reason-3.text" />
            </p>
          </div>
          <div className="flex flex-col items-center w-full md:w-1/2 xl:w-2/3 md:pl-4">
            <div className="max-w-xl w-full">
              <img src={SchedulingWithRecommendations} alt="" />
            </div>
          </div>
        </div>
      </div>
      <ConversionRow />
    </>
  );
};

export default Entrepreneurs;
